<template>
  <div>
    <data-table
      ref="dataTable"
      :actions="dataTable.actions"
      :data-class="audience"
      :default-sort="{ sortBy: dataTable.sortBy, order: dataTable.order }"
      :page-size="dataTable.pageSize"
      :start-page="startPage"
      :filters="typeFilter"
      double-header
      permission-suffix="audiences"
      @action="handleAction"
      @data-fetch="handleDataFetch"
      @selection-change="handleSelectionChange"
    >
      <div
        slot="additional-controls"
        slot-scope="{ selectedItemCount, archiveView }"
        class="controls"
      >
        <el-select
          v-model="audienceType"
          class="audience-type"
          clearable
          placeholder="Audience Type"
        >
          <el-option
            v-for="type in audienceTypeList"
            :key="type.value"
            :label="type.label"
            :value="type.value"
          />
        </el-select>
        <div v-show="busy">
          <positional-spinner
            right="0"
            static
            top="0"
          />
        </div>
        <el-button
          v-if="hasPermission(/^publish_/) && !archiveView"
          :disabled="selectedItemCount < 2 || mixedAudienceTypes || mixedOrgs"
          type="primary"
          @click="handleBulkPublishSelected"
        >
          Publish Selected
        </el-button>
        <el-button
          v-if="hasPermission('merge_audiences') && !archiveView"
          :disabled="selectedItemCount < 2 || notMergable"
          type="primary"
          @click="showMergeDialog = true"
        >
          Merge Selected
        </el-button>
        <el-button
          v-if="hasPermission('filter_audiences') && !archiveView"
          :disabled="selectedItemCount < 2"
          type="primary"
          @click="handleFilterSelected"
        >
          Filter Selected
        </el-button>
        <el-button
          v-if="hasPermission('archive_audiences') && !archiveView"
          :disabled="selectedItemCount === 0"
          type="danger"
          @click="handleAction('archiveMulti')"
        >
          Archive Selected
        </el-button>
        <el-button
          v-else-if="hasPermission('restore_audiences') && archiveView"
          :disabled="selectedItemCount === 0"
          type="primary"
          @click="handleAction('archiveMulti', null, { restore: true })"
        >
          Restore Selected
        </el-button>
      </div>

      <!-- TODO: Find and display list of attribution reports that reference this audience -->

      <el-table-column
        prop="name"
        label="Audience"
        sortable="custom"
      >
        <template slot-scope="data">
          <span v-if="data.row.parent && hasPermission('split_publish')">
            <el-tooltip
              class="item"
              effect="light"
              :content="`Source Audience: ${data.row.parent.name}`"
            >
              <i class="fa fa-fw fa-code-branch" />
            </el-tooltip>
          </span>
          <audience-type-tooltip :type="data.row.type" />
          &nbsp;
          {{ data.row.name }}
          <el-tag
            v-if="outdated(data.row.start_date)"
            class="outdated-tag"
            effect="dark"
            size="mini"
            type="danger"
          >
            out of date
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!picker"
        prop="count"
        label="Count"
        :width="110"
        sortable="custom"
      >
        <template slot-scope="data">
          <span v-if="data.row.count_error">
            <i class="fa fa-exclamation-triangle" />
          </span>
          <span v-else-if="data.row.count_pending">
            <i class="fa fa-sync fa-spin" />
          </span>
          <span v-else-if="data.row.historical">
            <el-tooltip
              class="item"
              effect="light"
              content="Historical Audience"
            >
              <i class="far fa-calendar-alt" />
            </el-tooltip>
          </span>
          <span v-else>{{ data.row.count | numeral }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="tags"
        label="Tags"
      >
        <template slot-scope="data">
          <el-tag
            v-for="tag in data.row.tags"
            :key="tag.id"
            size="mini"
          >
            {{ tag.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="picker"
        prop="start"
        label="Start"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        v-if="picker"
        prop="end"
        label="End"
        sortable="custom"
        :width="110"
      />
      <el-table-column
        prop="geo_count"
        label="# Geoframes"
        :width="130"
        sortable="custom"
      >
        <template slot-scope="data">
          <span
            v-if="!isDeviceAudience(data.row.type)"
          >{{ data.row.geo_count | numeral }}</span>
          <span v-else>N/A</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="organization"
        label="Organization"
        sortable="custom"
        :width="150"
      >
        <template slot-scope="data">
          {{ data.row.organization.name }}
        </template>
      </el-table-column>
      <el-table-column
        prop="created_at"
        label="Created"
        sortable="custom"
        :width="110"
      >
        <template slot-scope="data">
          {{ data.row.created_at | dateString }}
        </template>
      </el-table-column>
      <el-table-column
        prop="author"
        label="Author"
        sortable="custom"
        :width="130"
      >
        <template slot-scope="data">
          {{ data.row.author | fullName }}
        </template>
      </el-table-column>
    </data-table>

    <audience-dialog-merge
      :audiences="selectedRows"
      :show-dialog="showMergeDialog"
      @close="handleCloseMerge"
      @deselect="handleDeselection"
    />
    <audience-dialog-filter
      :audiences="selectedRows"
      :show-dialog="showFilterDialog"
      @close="handleCloseFilter"
      @deselect="handleDeselection"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import DataTable from '../global/DataTable.vue';
import AudienceDialogMerge from './AudienceDialogMerge.vue';
import AudienceDialogFilter from './AudienceDialogFilter.vue';
import { audience } from '@/adonis-api';
import AudienceTypeTooltip from '../global/AudienceTypeTooltip';
import PositionalSpinner from '../global/PositionalSpinner.vue';
import downloadFile from '@/helpers/download-file';
import DelayedAction from '@/helpers/delayed-action';
import { audienceTypes, isDeviceAudience } from '@/helpers';
import { audienceTypes as typeList } from '@/constants';
import jobWatcher from '@/mixins/job-watcher';
import { getAudienceMenu } from '@/menus';

const publishAudience = () => {};
const administerAudience = () => {};
const deleteAudiences = () => {};

export default {
  components: {
    AudienceDialogMerge,
    AudienceDialogFilter,
    AudienceTypeTooltip,
    DataTable,
    PositionalSpinner,
  },

  mixins: [jobWatcher],

  props: {
    picker: { type: Boolean, default: false },
    dateRange: {
      type: Array,
      default() {
        return [];
      },
    },
    fromDetailPage: { type: Boolean, default: false },
  },

  data() {
    return {
      attrDateRangeChosen: false,
      attrForm: {
        dateRange: [],
        title: '',
        useAudDate: false,
      },
      audienceData: {
        data: [],
        page: '1',
        perPage: '25',
        total: 0,
      },
      busy: false,
      chosenAudienceDateRange: [],
      dataTable: Object.freeze({
        actions: getAudienceMenu(this),
        order: 'desc',
        sortBy: 'created_at',
      }),
      editingAudience: {},
      selectedRows: [],
      showAttrDateRangePicker: false,
      showEditDialog: false,
      showMergeDialog: false,
      showFilterDialog: false,
      audienceType: null,
    };
  },

  computed: {
    ...mapGetters('settings', ['rangeStart', 'rangeEndDate', 'journeyGeoframeLimit']),
    ...mapGetters('user', [
      'hasHistoricalAccess',
      'permissions',
      'isInternal',
      'hasPermission',
    ]),
    ...mapState('audience', ['cloneJobId', 'dialog']),
    ...mapState('app', ['lastPageNum']),

    audienceTypeList() {
      return typeList.map(type => ({
        label: audienceTypes(type),
        value: type,
      }));
    },

    mixedOrgs() {
      return [...new Set(this.selectedRows.map(i => i.organization.id))].length > 1;
    },

    mixedAudienceTypes() {
      const polygon = this.selectedRows.map(i => i.type).some(i => !isDeviceAudience(i));
      const device = this.selectedRows.map(i => i.type).some(i => isDeviceAudience(i));

      return (polygon && device);
    },

    notMergable() {
      return this.selectedRows.some(item => item.count_pending || item.count_error) ||
             this.selectedRows.some(item => moment(item.start_date).isBefore(this.rangeStart));
    },

    startPage() {
      return !this.fromDetailPage ? 1 : (this.lastPageNum || 1);
    },

    typeFilter() {
      return this.audienceType ? { type: this.audienceType } : null;
    },
  },

  watch: {
    cloneJobId(jobId) {
      this.$refs.dataTable.invokeQuery();
      this.watchJob(jobId);
    },

    watchedJobs: {
      handler(jobs) {
        const completedOrFailedJobs = jobs.filter(
          job =>
            job.payload.status === 'COMPLETE' || job.payload.status === 'FAILED',
        );

        if (!completedOrFailedJobs.length) return;

        for (const { audienceId, count, journeyReportUrl, status, type } of completedOrFailedJobs.map(
          ({ payload }) => ({
            audienceId: payload.data.audience.id,
            count: payload.data.counts ? payload.data.counts.reduce((acc, cur) => acc + cur.count, 0) : 0,
            journeyReportUrl: payload.data.audience.journey_report_url ? payload.data.audience.journey_report_url : null,
            status: payload.status,
            type: payload.type,
          }),
        )) {
          if (type === 'AUDIENCE_JOURNEY') {
            this.$refs.dataTable.updateItemById(audienceId, {
              journey_report_url: journeyReportUrl,
            });
          } else {
            this.$refs.dataTable.updateItemById(audienceId, {
              count_error: status === 'FAILED',
              count_pending: false,
              count,
            });
          }
        }
      },
      deep: true,
    },
  },

  created() {
    this.audience = audience;
    this.stopSub = this.$store.subscribeAction(action => {
      if (
        action.type === 'event' &&
        action.payload.eventType === 'SAVED_AUDIENCE'
      ) {
        const { audience } = action.payload;
        this.$refs.dataTable.updateItemById(audience.id, audience);
      }
      if (
        action.type === 'event' &&
        action.payload.eventType === 'ADDED_AUDIENCE') {
        this.$refs.dataTable.invokeQuery();
      }
    });

    this.eventBus.$on('clear-selection', () => this.$refs.dataTable.clearSelection());
  },

  beforeDestroy() {
    this.stopSub && this.stopSub();
  },

  methods: {
    async handleAction(command, audience, options = {}) {
      const ids = this.selectedRows.map(row => row.id);
      let response, delayedAction;

      // Check for invalid date range first
      const earliestDate = this.rangeStart.format('YYYY-MM-DD');
      const latestDate = moment(this.rangeEndDate).format('YYYY-MM-DD');

      if (
        audience &&
        (audience.start_date < earliestDate || audience.end_date > latestDate) &&
        audience.historical
      ) {
        // const org = Organizations.findOne({
        //   organizationName: audience.organizationName,
        // });

        // Publish & extend will never work on historical audiences
        if (['publish', 'extend'].includes(command)) {
          this.$store.dispatch('audience/showDialog', [
            {
              audience,
              dialog: 'dateFixer',
            },
            {
              audience,
              dialog: command,
            },
          ]);

          return;
        } else if (command === 'download') {
          // This is ok, if the permissions are correct
          // FIXME:
          // const org = Organizations.findOne({
          //   organizationName: audience.organizationName,
          // });
          // if (!(this.hasHistoricalAccess && (org && org.historicalAccess))) {
          //   this.chosenAudience = audience;
          //   this.originalChoice = command;
          //   this.showDialog = 'dateFixer';
          //   return;
          // }
        } else if (command === 'audienceReports') {
          this.$notify.warning('Cannot run Insight Report on historical data.');
          return;
        }
      }

      switch (command) {
        case 'admin':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'admin',
          });
          break;

        case 'audienceReports':
          // await this.audience.prepareInsightReport(audience.id);
          // this.$message('Preparing insight report.');
          this.busy = true;
          response = await this.audience.getAudienceById(audience.id);
          if (response) {
            this.$refs.dataTable.updateItemById(audience.id, response);
          }
          this.busy = false;
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'audienceReports',
          });
          break;
        case 'viewEnhancedReports': {
          const queryParams = new URLSearchParams({
            reportId: audience.id,
            orientation: 'landscape',
          }).toString();

          const resolvedQuery = JSON.parse('{"' + decodeURI(queryParams).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

          const reportRoute = this.$router.resolve({
            path: '/audience/reports/title_page',
            query: resolvedQuery,
          });

          window.open(reportRoute.href, '_blank');
          break;
        }
        case 'clone':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'clone',
          });
          break;

        case 'delete':
          this.$confirm(
            `Are you sure you want to delete the audience "${
              audience.title
            }"?` + ' This operation cannot be undone.',
            'Delete Audience',
          )
            .then(() => deleteAudiences({ ids: [audience._id] }))
            .then(() => {
              this.$message('Audience deleted.');
            })
            .catch(err => {
              if (err !== 'cancel') {
                this.$reportError(err);
              }
            });
          break;

        case 'edit':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'edit',
          });
          break;

        case 'archiveMulti':
          try {
            await this.$confirm(
              `Are you sure you want to ${
                options.restore ? 'restore' : 'archive'
              } the ${
                this.$refs.dataTable.selectedItems.length
              } selected audiences?`,
              `${options.restore ? 'Restore' : 'Archive'} Audiences`,
              {
                type: 'warning',
              },
            );

            const ids = this.$refs.dataTable.selectedItems.map(row => row.id);

            // Clear row selections
            await this.audience.bulkDelete(ids, options);

            window.mixpanel.track('Archive Audiences', {
              'Audience IDs': ids,
            });

            this.$refs.dataTable.clearSelection();
            this.$refs.dataTable.invokeQuery();
            this.$message('Audiences archived.');
          } catch (e) {
            if (e !== 'cancel') {
              this.$reportError(e);
            }
          }
          break;

        case 'download':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'download',
          });
          break;

        case 'journeyReportGenerate':
          response = await this.audience.requestJourneyReport(audience.id);
          if (response.success) {
            this.$notify.info(response.message);
            if (response.data?.job) {
              this.watchJob(response.data.job.id);
              audience.journey_job.push({ id: response.data.job.id });
            }
          } else {
            this.$notify.error(response.message);
          }
          break;

        case 'journeyReportView':
          this.$router.push(`/audience/journey/${audience.uuid}`);
          break;

        case 'dwell-report-download':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'dwell-report-download',
          });
          break;

        case 'exportGeoframeInfo':
          try {
            (delayedAction = new DelayedAction(() => {
              this.$message('This might take a while, stand by...');
            }, 1500)).run();

            response = await this.audience.exportGeoframeInfo(audience.id);
            delayedAction.cancel();

            downloadFile(response, {
              filename: `${audience.name}_geoframes.csv`,
              mimeType: 'text/csv',
              prettify: false,
            });
          } catch (e) {
            this.$reportError(e);
          }

          break;

        case 'exportGeojson':
          try {
            await this.$confirm(
              `Are you sure you want to export the audience "${
                audience.name
              }" as geojson?`,
            );

            (delayedAction = new DelayedAction(() => {
              this.$message('This might take a while, stand by...');
            }, 1500)).run();

            response = await this.audience.exportGeojson(audience.id);
            delayedAction.cancel();

            downloadFile(response, {
              filename: `${audience.name}.geojson`,
              mimeType: 'application/vnd.geo+json',
              prettify: false,
            });

            window.mixpanel.track('Export Audience GeoJSON', {
              ID: audience.id,
              Name: audience.name,
            });

            break;
          } catch (e) {
            this.$reportError(e);
          }

          break;

        case 'extend':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'extend',
          });
          break;

        case 'publish':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'publish',
          });
          break;

        case 'reprepare':
          response = await this.audience.reprepare(audience.id);
          this.$message(response.message);
          break;

        case 'submitSelection':
          this.$emit('choose', ids);
          break;

        case 'viewDetails':
          this.$router.push(`/audiences/library/${audience.id}`);
          break;

        case 'generateProposal':
          this.busy = true;
          response = await this.audience.getAudienceById(audience.id);
          if (response) {
            this.$refs.dataTable.updateItemById(audience.id, response);
          }
          this.busy = false;
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'proposal',
          });
          break;
        case 'createTradeArea':
          this.$store.dispatch('audience/showDialog', {
            audience,
            dialog: 'tradeArea',
          });
          break;
        default:
          break;
      }
    },

    handleBulkPublishSelected() {
      this.$store.dispatch('audience/showDialog', {
        audience: {
          name: this.selectedRows[0].name,
          type: this.selectedRows[0].type,
          organization: this.selectedRows[0].organization,
          was_split: true,
          count: Math.max(...this.selectedRows.map(i => i.count)),
          __meta__: {
            files_count: Math.max(...this.selectedRows.map(i => +i.__meta__.files_count)),
          },
        },
        bulkIds: this.selectedRows.map(i => i.id),
        dialog: 'publish',
      });
    },

    handleFilterSelected() {
      if (this.selectedRows.length > 5) {
        this.$notify.warning({
          title: 'Warning',
          message: 'You can only filter up to 5 audiences at a time.',
        });
        return;
      }
      this.showFilterDialog = true;
    },

    handleCloseMerge({ clear }) {
      this.showMergeDialog = false;
      if (clear) {
        // clear will be true on save, clear selections and reload data.
        this.$refs.dataTable.clearSelection();
        this.$refs.dataTable.handlePageChange(1);
      }
    },

    handleCloseFilter({ clear }) {
      this.showFilterDialog = false;
      if (clear) {
        this.$refs.dataTable.clearSelection();
        this.$refs.dataTable.handlePageChange(1);
      }
    },

    handleDataFetch(audiences) {
      const audienceJobIds = audiences.map(aud => aud.job_id).filter(id => id);
      this.setWatchedJobs(audienceJobIds);
    },

    handleDeselection({ audience }) {
      this.$refs.dataTable.$refs.elTable.toggleRowSelection(audience);
    },

    handleSelectionChange(selections) {
      this.selectedRows = selections;
    },

    isDeviceAudience(type) {
      return isDeviceAudience(type);
    },

    outdated(startDate) {
      if (startDate) {
        return moment(startDate).isBefore(this.rangeStart);
      }

      return false;
    },

    blocked(audience) {},

    clearAnalytics() {
      administerAudience({
        audienceId: this.chosenAudienceId,
        operation: 'clearAnalytics',
      });
    },

    clearPublication(publicationId) {
      administerAudience({
        audienceId: this.chosenAudienceId,
        operation: 'clearPublication',
        data: publicationId,
      });
    },

    rerunPublication(publication) {
      publishAudience({
        id: publication.audienceId,
        partner: publication.partner,
        extension: publication.extension,
        audiencePubId: publication._id,
        credentials: publication.credentials,
      });
    },

    resetCountPending() {
      administerAudience({
        audienceId: this.chosenAudienceId,
        operation: 'resetCountPending',
      });
    },

    tagDisplay(row) {
      return row.tags ? row.tags.join(', ') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: inline-block;
  margin: 16px 0;
}

.search-box {
  max-width: 180px;
}

.small-text {
  font-size: 0.75em;
}

.el-dropdown {
  font-size: 1em;
}

.el-dropdown-menu {
  font-size: 0.75em;
}

.el-tag {
  margin-left: 4px;
}

.outdated-tag {
  font-size: 10px;
  padding: 0 2px;
  height: 16px;
  line-height: 15px;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  width: 50%;

  .audience-type {
    width: 150px;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
