<template>
  <div>
    <h3>Audience Library</h3>
    <audience-library-table :from-detail-page="fromDetailPage" />
  </div>
</template>

<script>
import AudienceLibraryTable from '../../audience/AudienceLibraryTable';

export default {
  components: {
    AudienceLibraryTable,
  },

  data() {
    return {
      fromDetailPage: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromDetailPage = /^\/audiences\/library\/\d+$/.test(from.path);
    });
  },
};
</script>
