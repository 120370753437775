<template>
  <div>
    <el-dialog
      title="Filter Selected"
      :visible="showDialog === true"
      :before-close="closeDialog"
      width="33%"
    >
      <el-alert
        v-if="selectedAudiences.length < 2"
        type="warning"
        title="Nothing To Filter"
        :closable="false"
        show-icon
      >
        You must have two or more audiences selected to perform a filter.
      </el-alert>
      <el-alert
        v-if="totalCount > COUNT_LIMIT"
        type="error"
        title="Oversized Audience"
        :closable="false"
        show-icon
      >
        Please refine your selections to remain below the maximum count limit of {{ COUNT_LIMIT | numeral }}
      </el-alert>

      <form>
        <div class="form-control">
          <label>Audience Name</label>
          <el-input v-model="title" />
        </div>

        <div class="form-control">
          <label>Organization</label>
          <org-picker
            :organization.sync="organization"
          />
        </div>

        <tag-picker :tags.sync="tags" />
      </form>

      <el-table
        :data="selectedAudiences"
        :summary-method="summaryRow"
        size="small"
        show-summary
      >
        <el-table-column
          label="Audience"
        >
          <template slot-scope="data">
            {{ data.row.name }}
          </template>
        </el-table-column>
        <el-table-column
          label="Count"
          width="100px"
        >
          <template slot-scope="data">
            {{ data.row.count | numeral }}
          </template>
        </el-table-column>

        <el-table-column
          width="60px"
        >
          <template slot-scope="data">
            <div class="trash-icon">
              <el-button
                type="danger"
                size="small"
                @click="remove(data.row)"
              >
                <i class="fa fa-trash" />
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          style="float:left"
          @click="closeDialog"
        >
          Close
        </el-button>
        <el-button
          :disabled="notReady"
          :loading="busy"
          type="primary"
          @click="createAudience"
        >
          Save Audience
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AudienceTypeTooltip from '../global/AudienceTypeTooltip';
import OrgPicker from '../global/OrgPicker';
import TagPicker from '../global/TagPicker';
import { isDeviceAudience } from '@/helpers';
import { audience } from '@/adonis-api';

const COUNT_LIMIT = 20000000;

export default {
  name: 'AudienceDialogFilter',
  components: {
    AudienceTypeTooltip,
    OrgPicker,
    TagPicker,
  },

  props: {
    audiences: { type: Array, required: true },
    showDialog: { type: Boolean, required: true },
  },

  data() {
    return {
      COUNT_LIMIT,
      busy: false,
      organization: this.$store.state.auth.orgDetails,
      selectedAudiences: [],
      title: null,
      tags: [],
    };
  },

  computed: {
    geoframeAudiences() {
      return this.selectedAudiences.some(item => !isDeviceAudience(item.type));
    },

    mixedAudiences() {
      return this.selectedAudiences.some(item => isDeviceAudience(item.type)) && this.selectedAudiences.some(item => !isDeviceAudience(item.type));
    },

    filterType() {
      return 'FILTERED';
    },

    notReady() {
      return (this.selectedAudiences.length < 2) ||
             (this.totalCount > COUNT_LIMIT) ||
             !this.title ||
             (this.title && !this.title.trim()) ||
             !this.organization;
    },

    totalCount() {
      if (this.selectedAudiences.length > 0) {
        return this.selectedAudiences.map(item => item.count).reduce((accumultor, current) => accumultor + current);
      }
      return 0;
    },

  },

  watch: {
    showDialog(val) {
      if (val) {
        this.selectedAudiences = [...this.audiences];
        this.resetForm();
        if (!this.organization?.id) {
          this.organization = this.$store.state.auth.orgDetails;
        }
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('close', { clear: false });
    },
    async createAudience() {
      this.busy = true;
      const params = {
        name: this.title.trim(),
        type: this.filterType,
        organization_id: this.organization.id,
        tags: this.tags,
        audience_ids: this.selectedAudiences.map(item => item.id),
        count: this.totalCount,
      };

      try {
        const response = await audience.filterAudiences(params);
        if (window.mixpanel.track) {
          window.mixpanel.track('Filter Audiences', {
            ID: response.data.id,
            Name: response.data.name,
            Organization: this.organization.name,
            Count: this.totalCount,
            AudienceIDs: this.selectedAudiences.map(item => item.id),
          });
        }

        this.$notify.success({
          message: this.mixedAudiences ? 'Your filtered audience is being prepared.' : 'Your filtered audience has been saved.',
        });
        this.resetForm();
      } catch (e) {
        this.$notify.error({
          message: 'An error occurred while saving the audience. This issue has been reported.',
        });
        this.$reportError(e, {
          componentName: this.$options.name,
          params,
        });
      } finally {
        this.busy = false;
        this.$emit('close', { clear: true });
      }
    },

    isDeviceAudience(type) {
      return isDeviceAudience(type);
    },
    remove(audience) {
      const idx = this.selectedAudiences.findIndex((item) => item.id === audience.id);
      if (idx > -1) {
        this.selectedAudiences.splice(idx, 1);
      }
      this.$emit('deselect', { audience });
    },
    resetForm() {
      this.title = null;
      this.tags = [];
    },
    summaryRow() {
      return [
        'Totals',
        this.totalCount.toLocaleString(),
        '',
        '',
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  .el-input,
  .el-select,
  .el-button {
    width: 100%;
  }
  .el-tag {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
}
.trash-icon {
  text-overflow: clip;
}

</style>
